import React, { Component, createRef, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import isNull from 'lodash/isNull';
import has from 'lodash/has';
import InquireForm from './Inquire/Form';
import { defaultLotProps, defaultUserProps, lotPropTypes, user as userPropTypes } from '../PropTypes/proptypes';
import getPhillipsBackboneProperty from '../../utils/getPhillipsBackboneProperty';
import sendAnalytics from '../../utils/sendAnalytics';
import setI18nLabel from '../../utils/getI18nLabel';
import getAuth from '../Auth/getAuth';

const getDetailWidgetHtml = (auctionMobilityLotRowId, endSale, status) => {
  const className = classNames('am-widget', {
    'am-widget--widget-connect-failure': status === 'widgetConnectFailure'
  });
  const htmlArray = [
    `<div class="${className}" id="detail-am-target-${auctionMobilityLotRowId}" lot-id="${auctionMobilityLotRowId}">`
  ];
  if (status === 'widgetConnectFailure') {
    htmlArray.push(`<button class="am-widget--widget-connect-failure__button" onclick="(function () {location.reload();})()" type="button"><img class="am-widget--widget-connect-failure__image" src="https://phillips.vo.llnwd.net/v1/web_prod/images/layout/reload_white.svg" alt="Unable to connect to server."/><h3>Network Connection</h3><p>We've detected a network problem with your
    connection. Please <span class="montserratMedium">reload</span> the page to see updated information.</p></button></div>`);
  } else {
    if (!endSale) {
      htmlArray.push(
        '<div class="row"><am-timer class="col-sm-2 am-widget__timer am-widget__timer--detail"></am-timer></div>'
      );
    }
    htmlArray.push(`<div class="row am-widget__bid am-widget__bid--detail"><am-bid-status-label class="am-widget__bid__status am-widget__bid__status--detail"></am-bid-status-label><am-bid-count-label class="am-widget__bid__count am-widget__bid__count--detail"></am-bid-count-label></div><div class="row"><am-bid-box class="col-sm-2" v-on:action="onBidConfirmation"></am-bid-box></div><am-lot-status-label class="am-widget__lot__status am-widget__lot__status--detail"></am-lot-status-label><img class="hidden"
  src="https://phillips.vo.llnwd.net/v1/web_prod/images/layout/reload_white.svg" /></div>`);
  }
  return htmlArray.join('');
};

const getWidgetHtml = (
  auctionMobilityLotRowId,
  endSale
) => `<div class="am-widget" id="am-target-${auctionMobilityLotRowId}" lot-id="${auctionMobilityLotRowId}"><am-lot-timer class="am-widget__timer"></am-lot-timer><div class="row am-widget__lot ${endSale ? 'hide' : ''
}"><am-lot-status-label class="am-widget__lot__status am-widget__lot__status--grid col-sm-2"></am-lot-status-label></div><div class="row am-widget__bid am-widget__bid--grid"><am-bid-status-label class="am-widget__bid__status"></am-bid-status-label><am-bid-count-label class="am-widget__bid__count"></am-bid-count-label><am-reserve-label
class="am-widget__bid__reserve col-sm-2"></am-reserve-label></div></div>`;

class BidButton extends Component {
  constructor(props) {
    super(props);
    this.bbEventRef = createRef();
    this.windowRef = createRef();
    this.lotWidgetWrapper = createRef();
    const widgetHtml =
      props.layout === 'grid'
        ? getWidgetHtml(props.lot.auctionMobilityLotRowId, props.lot.endSale)
        : getDetailWidgetHtml(props.lot.auctionMobilityLotRowId, props.lot.endSale, props.widgetStatus);
    this.state = {
      offerStatus: props.offerStatus,
      offer: props.offer,
      termsAgreed: false,
      showModal: false,
      error: props.error,
      widgetHtml,
      widgetStatus: props.widgetStatus,
      hrefUrl: ''
    };
    this.getHrefUrl = this.getHrefUrl.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.sendAnalytics = this.sendAnalytics.bind(this);
    this.openFormModal = this.openFormModal.bind(this);
  }

  componentDidMount() {
    const {
      auctionBidPartner,
      auctionMobilityLotRowId,
      endSale,
      isMobile,
      saleTypeId,
      showSaleOffers
    } = this.props.lot;
    const widgetEnabled =
      !showSaleOffers && !endSale && ((saleTypeId === 3 && auctionBidPartner === 1) || saleTypeId === 6);
    getPhillipsBackboneProperty('Events').then(bbEvent => {
      this.bbEventRef.current = bbEvent;
    });
    if (typeof window !== 'undefined') {
      this.windowRef.current = window;
      this.setState({hrefUrl: window.location.href});
    }
    if (widgetEnabled) {
      this.setAmWidget();
    }
  }

  componentWillReceiveProps(nextProps) {
    const widgetHtml =
      this.props.layout === 'grid'
        ? getWidgetHtml(nextProps.lot.auctionMobilityLotRowId, nextProps.lot.endSale)
        : getDetailWidgetHtml(nextProps.lot.auctionMobilityLotRowId, nextProps.lot.endSale, nextProps.widgetStatus);
    if (nextProps.lot.lotNumberFull.trim() !== this.props.lot.lotNumberFull.trim()) {
      this.setState(state => ({
        ...state,
        offerStatus: '',
        offer: 0,
        error: null,
        widgetHtml
      }));
    } else {
      this.setState(state => ({
        ...state,
        offerStatus: nextProps.offerStatus,
        offer: nextProps.offer,
        error: nextProps.error,
        widgetHtml
      }));
    }
  }

  componentDidUpdate({ lot: prevLot, widgetStatus: prevWidgetStatus }) {
    const { layout, widgetStatus, user } = this.props;
    const {
      auctionBidPartner,
      auctionMobilityLotRowId,
      endSale,
      isMobile,
      saleTypeId,
      showSaleOffers,
      language
    } = this.props.lot;
    const widgetEnabled =
      !showSaleOffers && !endSale && ((saleTypeId === 3 && auctionBidPartner === 1) || saleTypeId === 6);
    if (showSaleOffers && typeof localforage !== 'undefined' && user.loggedIn) {
      this.windowRef.current.localforage.getItem('offer').then(offer => {
        if (offer) {
          this.submitOffer({
            ...user,
            ...prevLot,
            ...offer,
            language
          });
        }
      });
      return;
    }
    if (widgetEnabled) {
      if (!has(this.windowRef.current, 'detailLotWidget') && layout === 'detail') {
        // check to see if isMobile or if it's desktop and re-initializing
        if (isMobile || prevLot.isMobile === isMobile) {
          this.setAmWidget();
        }
        return;
      }
      if (prevLot.auctionMobilityLotRowId !== auctionMobilityLotRowId) {
        this.setAmWidget();
        return;
      }
      // if widgetConnectFailure => widgetConnectSuccess || vice versa
      // if '' => widgetConnectSuccess || '' => widgetConnectFailure
      if (prevWidgetStatus.length > 0 && prevWidgetStatus !== widgetStatus) {
        if (prevWidgetStatus !== 'widgetConnectFailure' && widgetStatus !== 'widgetConnectSuccess') {
          console.log('reload the page');
          this.setAmWidget();
        }
      }
    }
  }

  getHrefUrl() {
    const perpetualSaleNumber = typeof sessionStorage !== "undefined" ? sessionStorage.getItem('perpetualSaleNumber'):"";
    return this.props.lot.saleTypeId === perpetualSaleNumber
      ? this.props.lot.saleNumber === perpetualSaleNumber
        ? `mailto:${this.props.contactEmail}?subject=${this.props.lot.description} ${this.props.lot.auctionLotPublicId}`
        : `mailto:${this.props.contactEmail}?subject=Private sale inquiry: ${this.props.lot.description} by ${this.props.lot.makerName
        } (${this.props.lot.auctionLotPublicId}, ${!isNull(this.windowRef.current) ? this.windowRef.current.location.href : 'placeholder'
        })`
      : this.props.lot.showInquireButton && this.props.lot.saleTypeId === 5
        ? `mailto:${this.props.lot.conditionRequestEmail}?subject=Inquiry about lot ${this.props.lot.lotNumber
        } in ${encodeURIComponent(this.props.lot.saleTitle)}`
        : `${this.props.lot.partnerBidUrl}&return_to_widget=${this.state.hrefUrl}`;
  }

  setAmWidget() {
    this.lotWidgetWrapper.current.innerHTML = this.state.widgetHtml;
  }

  setBidButtonText() {
    const getI18nLabel = setI18nLabel(this.props.lot.language);
    let bidButtonText =
      this.props.lot.saleTypeId === 3 && this.props.lot.showAdvanceBidButton
        ? getI18nLabel({ label: 'placeBid' })
        : this.props.lot.showAdvanceBidButton && this.props.lot.saleTypeId !== 5 ? getI18nLabel({ label: 'placeAdvanceBid' }):false;
    if (this.props.lot.auctionBidPartner === 2) {
      if (this.props.lot.partnerBidUrl.indexOf('mailto') > -1) {
        bidButtonText = getI18nLabel({ label: 'inquire' });
      } else {
        bidButtonText = getI18nLabel({ label: 'placeBidArtsy' });
      }
    } else if (this.props.lot.saleTypeId === 5) {
      bidButtonText = getI18nLabel({ label: 'inquire' });
    }
    return bidButtonText;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { lot, user } = this.props;
    const { conditionRequestEmail } = lot;
    const formData = serialize(e.target, { hash: true });

    const offerData = {
      ...formData,
      emailTo: conditionRequestEmail
    };
    const asyncLocalStorage = {
      setItem(key, value) {
          return Promise.resolve().then(function () {
              localStorage.setItem(key, value);
          });
      },

    };
    if (!user.loggedIn) {
      if (this.windowRef.current.matchMedia('screen and (min-width: 1024px) and (min-height: 775px)').matches) {
        const deferred = new Promise((resolve, reject) => {
          getAuth().then(auth => auth.login());
        });
        deferred.then(response => {
          this.submitOffer({
            ...user,
            ...response,
            ...lot,
            ...offerData
          });
        });
      } else if (typeof localStorage !== 'undefined') {
        asyncLocalStorage.setItem('offer', offerData).then(() => {
          getAuth().then(auth => auth.login());
        });
      }
    } else {
      const data = { ...user, ...lot, ...offerData };
      this.submitOffer(data);
    }
  }

  submitOffer(offerData) {
    const { lot, offerReject, submitOffer, user, language } = this.props;
    const getI18nLabel = setI18nLabel(language);
    const offer = parseInt(offerData.offer, 10);
    const threshold = parseInt(lot.saleOfferThreshold, 10);
    this.windowRef.current.localforage.removeItem('offer');
    if (lot.saleTypeId === 4) {
      if (!this.state.termsAgreed) {
        this.setState(state => ({
          ...state,
          error: {
            message: getI18nLabel({ label: 'submitOfferError' })
          }
        }));
      }
    }
    if (threshold <= offer) {
      submitOffer({
        offerData,
        payload: {
          offer,
          user,
          lot
        }
      });
    } else {
      offerReject({
        user,
        lot,
        offer,
        offerStatus: 'rejected',
        error: null
      });
    }
  }

  sendAnalytics() {
    const data = {
      eventCategory: `${this.props.lot.showInquireButton && this.props.lot.saleTypeId === 5 ? 'Inquire Click' : 'Bid Button'}`,
      eventAction: this.setBidButtonText(),
      eventLabel: `${this.props.lot.saleNumber} ${this.props.lot.lotNumber}`
    };
    sendAnalytics(data);
  }

  openFormModal() {
    this.props.showModal({ type: 'inquire' });
    const { auctionLotPublicId, department, lotNumber, saleNumber, saleTitle } = this.props.lot;
    let eventAction = 'Inquire - Private Sales';
    let eventLabel = `Private Sales - ${saleNumber} ${lotNumber}`;
    if (saleTitle.toLowerCase().indexOf('perpetual') > -1) {
      eventAction = 'Inquire - Perpetual Store';
      eventLabel = `Perpetual - ${auctionLotPublicId}`;
    } else if (saleTitle.toLowerCase().indexOf('flawless') > -1 || department.toLowerCase() === 'jewelry') {
      eventAction = 'Inquire - Flawless';
      eventLabel = `Flawless - ${saleNumber} ${lotNumber}`;
    }
    const data = {
      eventCategory: 'Bid Button',
      eventAction,
      eventLabel
    };
    sendAnalytics(data);
  }

  buyButtonRedirect(objNum) {
    if (typeof gtag == 'function') {
      gtag('event', 'buyItNow_clickEvent');
    }
    let url = this.props.websiteUrl + 'account/orders/create?objectNum=' + objNum;
    !isNull(this.windowRef.current) ? this.windowRef.current.location.href=url: '';
  }

  render() {
    const { layout } = this.props;
    const {
      auctionBidPartner,
      auctionLotPublicId,
      department,
      description,
      endSale,
      isMobile,
      lotNumber,
      makerName,
      objectNumber,
      partnerBidUrl,
      saleNumber,
      saleTitle,
      saleTypeId,
      showAdvanceBidButton,
      showInquireButton,
      showBuyNowButton,
      showSaleOffers,
      language
    } = this.props.lot;
    const getI18nLabel = setI18nLabel(language);
    const { error, offerStatus, termsAgreed } = this.state;
    const widgetEnabled =
      !showSaleOffers && !endSale && ((saleTypeId === 3 && auctionBidPartner === 1) || saleTypeId === 6); // AM Online Only (Widget) on SalePage
    if (widgetEnabled && layout === 'grid') {
      return <div ref={this.lotWidgetWrapper} className="row am-widget__wrapper" />;
    }
    // AM Online Only on LotPage
    if (widgetEnabled) {
      return (
        <Fragment>
          <div ref={this.lotWidgetWrapper} className="row widget-wrapper" />
          <p
            className={classNames('online-only-disclaimer', { hide: endSale || layout === 'grid' })}
            dangerouslySetInnerHTML={{ __html: getI18nLabel({ label: 'onlineOnlyDisclaimer' }) }}
          />
        </Fragment>
      );
    }
    // Specific logic for Perpetual Buy Now button
    if (saleTypeId === 5 && showInquireButton && showBuyNowButton) {
      return(
        <Fragment>
          <button className="buy-button" type="button" onClick={() => this.buyButtonRedirect(objectNumber)}>
            {getI18nLabel({ label: 'buyWatch' })}
          </button>
          <button className="place-bid-button" onClick={this.openFormModal} type="button">
            {getI18nLabel({ label: 'contactSpecialist' })}
          </button>
        </Fragment>
      )
    }
    // saleTypeId 5 === Private Sales || Perpetual || Flawless
    if (saleTypeId === 5 && showInquireButton) {
      return !isMobile ? (
        <button className="place-bid-button" onClick={this.openFormModal} type="button">
          {getI18nLabel({ label: 'contactSpecialist' })}
        </button>
      ) : (
        <InquireForm
            auctionLotPublicId={auctionLotPublicId}
            isMobile={isMobile}
            lotNumber={lotNumber}
            objectNumber={objectNumber}
            department={department}
            description={description}
            makerName={makerName}
            saleNumber={saleNumber}
            saleTitle={saleTitle}
          />
        );
    }
    if (showSaleOffers) {
      const offerMsg =
        saleTypeId === 4
          ? getI18nLabel({ label: 'privateSalesOfferOpen' })
          : getI18nLabel({ label: 'postSaleOfferOpen' });
      return (
        <div className="sale-offer">
          <p className="offer-message">{offerMsg}</p>
          <form onSubmit={this.handleFormSubmit} className="clearfix">
            <div className="input-wrapper">
              <label htmlFor="offer">{getI18nLabel({ label: 'makeAnOffer' })}</label>
              <input
                id="offer"
                type="number"
                name="offer"
                step="1"
                placeholder={`${this.props.lot.currencySign}0`}
                disabled={this.state.offerStatus === 'pending' || this.state.offerStatus === 'received'}
                required={this.state.offerStatus !== 'pending' || this.state.offerStatus !== 'received'}
              />
            </div>
            {error ? <p className="error">{error.message}</p> : null}
            <button disabled={offerStatus === 'pending' || offerStatus === 'received'} type="submit">
              {offerStatus.length > 0
                ? getI18nLabel({ label: 'saleOfferStatus', args: [getI18nLabel({ label: offerStatus })] })
                : getI18nLabel({ label: 'submitOffer' })}
            </button>
            {saleTypeId === 4 ? (
              <span className="phillips-box">
                <input
                  type="checkbox"
                  name="offer-terms"
                  id="offer-terms"
                  className="hidden"
                  value={termsAgreed}
                  onChange={() => {
                    this.setState(state => ({ ...state, termsAgreed: !termsAgreed, error: null }));
                  }}
                />
                <label className="check-box" htmlFor="offer-terms" />
                <label
                  htmlFor="offer-terms"
                  dangerouslySetInnerHTML={{ __html: getI18nLabel({ label: 'postSaleOfferCheckbox' }) }}
                />
              </span>
            ) : null}
          </form>
        </div>
      );
    }
    if(showAdvanceBidButton && !endSale && saleTypeId !== 5){
      if(saleTypeId === 3 ){
        return (
          <a
            className="place-bid-button"
            href={this.getHrefUrl()}
            onClick={this.sendAnalytics}
            title={getI18nLabel({ label: 'placeBid' })}
          >
            {getI18nLabel({ label: 'placeBid' })}
          </a>
        )
      }
        return (
          <a
            className="place-bid-button"
            href={this.getHrefUrl()}
            onClick={this.sendAnalytics}
            title={getI18nLabel({ label: 'placeAdvanceBid' })}
          >
            {getI18nLabel({ label: 'placeAdvanceBid' })}
          </a>
        )

    }

    if (auctionBidPartner === 2) {
      if (partnerBidUrl.indexOf('mailto') > -1) {
         return (
           <a
             className="place-bid-button"
             href={this.getHrefUrl()}
             onClick={this.sendAnalytics}
             title={getI18nLabel({ label: 'inquire' })}
           >
             {getI18nLabel({ label: 'inquire' })}
           </a>
        )
      }
        return (
          <a
            className="place-bid-button"
            href={this.getHrefUrl()}
            onClick={this.sendAnalytics}
            title={getI18nLabel({ label: 'placeBidArtsy' })}
          >
            {getI18nLabel({ label: 'placeBidArtsy' })}
          </a>
        )

    }
    if (saleTypeId === 5 && showInquireButton) {
      return (
        <a
          className="place-bid-button"
          href={this.getHrefUrl()}
          onClick={this.sendAnalytics}
          title={getI18nLabel({ label: 'placeBidArtsy' })}
        >
          {getI18nLabel({ label: 'inquire' })}
        </a>
        )
    }

      return false;

  }
}

BidButton.defaultProps = {
  contactEmail: '',
  layout: 'detail',
  lot: defaultLotProps,
  offerReject() {
    console.log('Please add offerReject handler');
  },
  offerStatus: '',
  showModal() {
    console.log('Please add showModal handler.');
  },
  submitOffer() {
    console.log('Please add submitOffer handler.');
  },
  widgetStatus: '',
  user: defaultUserProps.user
};

BidButton.propTypes = {
  contactEmail: PropTypes.string,
  layout: PropTypes.string,
  lot: PropTypes.shape(lotPropTypes),
  offerReject: PropTypes.func,
  offerStatus: PropTypes.string,
  showModal: PropTypes.func,
  submitOffer: PropTypes.func,
  widgetStatus: PropTypes.string,
  user: PropTypes.shape(userPropTypes.user)
};

export default BidButton;
